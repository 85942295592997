import './Descarga.css';
import { useState, useEffect } from 'react';

const Descarga = () => {
  const [isAppInstalled, setIsAppInstalled] = useState(false);

  useEffect(() => {
    const checkIfAppInstalled = () => {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        setIsAppInstalled(true);
      }
    };

    window.addEventListener('load', checkIfAppInstalled);

    return () => {
      window.removeEventListener('load', checkIfAppInstalled);
    };
  }, []);

  const handleDownloadAPK = () => {
    try {
      const isAndroid = /Android/i.test(navigator.userAgent);
      if (isAndroid) {
        const link = document.createElement('a');
        link.href = 'https://nomad.com.ar/pwa/nomad.apk';
        link.download = 'nomad.apk';
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        alert('La descarga de la APK comenzará en breve.');
      } else {
        alert('La aplicación solo está disponible para dispositivos Android.');
      }
    } catch (error) {
      console.error('Error al descargar la APK:', error);
      alert('No se pudo iniciar la descarga. Intenta más tarde.');
    }
  };

  return (
    <div className="container descarga" id="descarga">
      <div className="row align-items-center">
        <div className="col-12 col-md-6 mb-4 mb-md-0">
          <h2 className="title">Descarga nomad hoy</h2>
          <p><strong>¿Listo para trabajar desde cualquier lugar?</strong></p>
          <p>Descarga la app y explora espacios de trabajo en tu ciudad. La productividad está a un clic de distancia.</p>
          <button
            className="btn btn-primary mt-4"
            onClick={handleDownloadAPK}
            aria-label="Descargar Nomad APK"
          >
            {isAppInstalled ? 'Actualiza nomad aquí' : 'Descarga nomad aquí'}
          </button>
        </div>
        <div className="col-12 col-md-6 text-center">
          <img className="img-fluid" alt="Dispositivo mostrando la app Nomad" src="./images/descarga.svg" />
        </div>
      </div>
    </div>
  );
};

export default Descarga;
